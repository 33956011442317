import React from "react"

import Layout from "../layouts/default"

import TextContainer from "../components/elements/text_container"

export default () => {

  const page = {
    name: 'Error',
    slug: 'error',
    seo: {
      title: 'Fehler',
      robots: 'noindex,follow'
    }
  };
  return (
      <Layout page={page}>
        <TextContainer>
          <h1>:( Das hat leider nicht funktioniert.</h1>
        </TextContainer>
      </Layout>
  )
};
